<template>
  <vx-card class="tabla-franquicias">
    <!-- TABLE ACTION ROW -->
    <div class="flex justify-between items-center">
      <vs-input
        class="mb-4 w-full md:mb-0 mr-4"
        v-model="searchQuery"
        @input="updateSearchQuery"
        placeholder="Buscar..."
      />
      <div>
        <vs-button class="mb-4 md:mb-0">Añadir</vs-button>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="franchises"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="maxPageNumbers"
      v-model="currentPage"
    />
  </vx-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import CellRendererCheckbox from './cell-renderer/CellRendererCheckbox';
import { FRANCHISES_PAGINATED } from '@/graphql/queries';
import CellRendererEdit from './cell-renderer/CellRendererEdit';
import CellRendererCommerceType from './cell-renderer/CellRendererCommerceType';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
export default defineComponent({
  components: {
    AgGridVue,
    CellRendererCheckbox,
    CellRendererEdit,
    CellRendererCommerceType,
  },
  setup() {
    const currentPage = ref(1);
    const first = ref(15);
    const { result: franchisesResult } = useQuery(FRANCHISES_PAGINATED, {
      page: currentPage,
      first: first,
    });
    const franchises = useResult(
      franchisesResult,
      [],
      (data) => data.franchisesPaginated.data
    );
    const paginatorInfo = useResult(franchisesResult, [], (data) => ({
      total: data.franchisesPaginated.paginatorInfo.total,
      perPage: data.franchisesPaginated.paginatorInfo.perPage,
    }));
    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );
    return {
      totalPages,
      paginatorInfo,
      franchises,
      currentPage,
    };
  },
  data() {
    return {
      searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'Ref',
          field: 'id',
          width: 175,
          filter: true,
        },
        {
          headerName: 'Razón social',
          field: 'name',
          width: 175,
          filter: true,
        },
        {
          headerName: 'NIT',
          field: 'nit',
          filter: true,
          width: 250,
        },
        {
          headerName: 'Contacto',
          field: 'contact_name',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Teléfono',
          field: 'phone',
          filter: true,
          width: 170,
        },
        {
          headerName: 'Tipo de negocio',
          field: 'commerce_type',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererCommerceType',
        },
        {
          headerName: 'Dirección',
          field: 'address',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Medios de entrega',
          field: 'delivery_media',
          filter: true,
          width: 150,
        },

        {
          headerName: 'Horario de atención',
          field: 'attention_schedule',
          filter: true,
          width: 120,
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererCheckbox',
        },
        {
          headerName: 'Editar',
          field: 'editar',
          width: 150,
          cellRendererFramework: 'CellRendererEdit',
        },
      ],
      //franchises: { data: [] },
    };
  },
  watch: {
    '$store.state.windowWidth'(val) {
      if (val <= 576) {
        this.maxPageNumbers = 5;
        // this.gridOptions.columnApi.setColumnPinned('email', null);
      }
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
});
</script>
