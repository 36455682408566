<template>
  <div>
    <vs-icon
      v-if="params.value"
      size="small"
      color="primary"
      icon="radio_button_checked"
    ></vs-icon>
    <vs-icon v-else size="small" icon="radio_button_unchecked"></vs-icon>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
