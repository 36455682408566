<template>
  <div>
    <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="editRecord"
    />
  </div>
</template>

<script>
export default {
  name: 'CellRendererEdit',
  methods: {
    editRecord() {
      this.$gates.hasPermission('edit_franchises') &&
        this.$router
          .push(
            '/terceros/franquicias/editar-franquicia/' + this.params.data.id
          )
          .catch(() => {});

      /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose
              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
    },
  },
};
</script>
